<template>
  <div class="GolfMemberShip">
    <div class="homepage">
      <h2 class="Orangetitle">{{acf.acf.golfpark_title}}</h2>
      <p class="blackStandard-text golfParkText-width" v-html="acf.acf.golfpark_blog"></p>
      <b-link :to="'/contact'">
        <b-button class="golfMemberBtn" variant="">Enquire Today</b-button>
      </b-link>
      <b-row class="membership-Options container">
        <b-col lg="6" sm="12">
            <div class="borderMemberShip">
                <h2 class="blacktitle">{{acf.acf.golfpark_option_one}}</h2>
                <h3 class="Membership-Prices">From £{{acf.acf.golfpark_option_one_price}}<span class="perMonth">/per month</span></h3>
                <ul class="MemberBullet-Points">
                    <li v-for="(optionone, index) in acf.acf.golfpark_option_one_list" :key="index">{{optionone.golf_park_membership_list_one}}</li>
                    <!-- <li>Allows for up to 4 players per session</li> -->
                </ul>
                <div class="memberEnquiry">
                    <b-link :to="'/contact'">
                        <b-button class="MemberBtn">Enquire Today</b-button>
                    </b-link>
                </div>
            </div>
        </b-col>
        <b-col lg="6" sm="12" >
            <div class="borderMemberShip">
                <h2 class="blacktitle">{{acf.acf.golfpark_option_two}}</h2>
                <h3 class="Membership-Prices">From £{{acf.acf.golfpark_option_two_price}}<span class="perMonth">/per month</span></h3>
                <ul class="MemberBullet-Points">
                    <li v-for="(optiontwo, index) in acf.acf.golfpark_option_two_list" :key="index">{{optiontwo.golf_park_membership_list_two}}</li>
                    <!-- <li>Allows for up to 4 players per session</li> -->
                </ul>
                <div class="memberEnquiry">
                    <b-link :to="'/contact'">
                        <b-button class="MemberBtn">Enquire Today</b-button>
                    </b-link>
                </div>
            </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Golf Park Memberships',
  components: {

  },
  data: function() {
    return {
      acf: [],
    };
  },
  methods: {
    isMobile() {
        if( screen.width <= 500 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    axios //add API Call
        .get(
           process.env.VUE_APP_API_URL + 'golfpark' + "/wp-json/wp/v2/pages/25"
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
            this.acf = response.data
        )); //API data wanted pulled out
  },
}
</script>

<style scoped>
button.MemberBtn {
    background-color: #FF8B17;
    border-color: #FF8B17!important;
    border-radius: 0px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    height: 54px;
    font-family: "noto-sans", sans-serif;
    /* height: 35px; */
}
button.MemberBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
}
.memberEnquiry {
    position: absolute;
    width: 100%;
    padding-left: 15px;
    padding-right: 50px;
    bottom: 14px;
}
.Orangetitle {
    color: #FF8B17;
    font-size: 40px;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
}
.blacktitle {
    color: #11182F;
    font-size: 40px;
    font-family: "korolev", sans-serif;
    font-weight: 700;
    font-style: italic;
}
.GolfMemberShip {
    padding: 50px;
}
.blackStandard-text {
    font-size: 24px;
    color: #11182F;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.golfParkText-width {
    width: 70%;
    text-align: center;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}
button.golfMemberBtn {
    background-color: #1A1A1A;
    border-color: #1A1A1A!important;
    border-radius: 0px;
    width: 163px;
    height: 31px;
    line-height: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    width: 535px;
    height: 54px;
    margin-top: 25px;
    margin-bottom: 50px;
    font-family: "noto-sans", sans-serif;
}
button.golfMemberBtn:hover {
    background-color: #707070;
    border-color: #707070!important;
    color: #fff;
}
.membership-Options {
    margin-top: 60px;
    margin: auto;
}
h3.Membership-Prices {
    color: #FF8B17;
    font-size: 29px;
    padding-top: 5px;
    font-family: "noto-sans", sans-serif;
    font-weight: 700;
    font-style: normal;
}
.perMonth {
    color: #FF8B17;
    font-size: 15px;
    font-weight: bold;
}
.borderMemberShip {
    border: 1px solid #11182F;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 400px;
}
ul.MemberBullet-Points {
    color: #11182F;
    font-size: 24px;
    text-align: left;
    list-style-type: circle;
    margin-top: 50px;
    font-family: "noto-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
ul.MemberBullet-Points > li {
    padding-bottom: 10px;
}

/* MobileCSS */
@media only screen and (max-width: 768px) {
    h3.Membership-Prices {
        font-size: 20px;
    }
    .blacktitle {
        font-size: 36px;
    }
    .blackStandard-text {
        font-size: 20px;
        color: #11182F;
        font-family: "noto-sans", sans-serif;
        font-weight: 400;
        font-style: normal;
    }
    .Orangetitle {
        font-size: 36px;
    }
    .GolfMemberShip {
        padding: 25px;
    }
    .container {
        padding-left: 0;
        padding-right: 0;
    }
    button.golfMemberBtn {
        background-color: #1A1A1A;
        border-color: #1A1A1A!important;
        border-radius: 0px;
        width: 163px;
        height: 31px;
        line-height: 1;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        height: 54px;
        margin-top: 25px;
    }
    button.golfMemberBtn:hover {
        background-color: #FF8B17;
        border-color: #FF8B17!important;
        color: #fff;
    }
    .golfParkText-width {
        width: 100%; 
        text-align: center;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .borderMemberShip {
        height: auto;
        margin-bottom: 20px;
    }
    ul.MemberBullet-Points {
        padding-left: 30px; 
        font-size: 20px;
        padding-bottom: 30px;
    }
    .memberEnquiry {
        bottom: 35px;
    }
}
</style>
