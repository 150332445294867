<template>
  <div id="Home">
    <!-- <Home /> -->
    <HomeTopImage />
    <EnquireyBanner />
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_2.png">
    </template>
    <div id="about">
      <AboutTrackman />
    </div>
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_7.png">
    </template>
    <template v-else>
      <GolfParkImages />
    </template>
    <div id="membership">
      <GolfMember />
    </div>
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_9.png">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_5.png">
    </template>
  </div>
</template>

<script>
import HomeTopImage from '@/components/HomeTopImage.vue'
import EnquireyBanner from '@/components/EnquireyBannerOne.vue'
import AboutTrackman from '@/components/AboutTrackman.vue'
import GolfParkImages from '@/components/GolfParkImages.vue'
import GolfMember from '@/components/GolfParkMember.vue'

export default {
  name: 'App',
  components: {
    HomeTopImage,
    EnquireyBanner,
    AboutTrackman,
    GolfParkImages,
    GolfMember,
  },
  methods: {
    isMobile() {
        if( screen.width <= 768 ) {
            return true;
        }
        else {
            return false;
        }
    },
  }
}
</script>
<style scoped>
::v-deep .btn-danger {
  color: #fff;
  background-color: #7ebd63;
  border-color: #7ebd63!important;
}
.imagesInsert {
  width: 100%;
}
</style>

